<template>
  <div class="carousel-time-page">
    <div class="return-today">
      <div class="today" @click="returnFun">今天</div>
    </div>
    <div class="time">
      <div
        style="margin-left: 48px"
        class="time-img"
        @click="changeTimeList(-3)"
      >
        <img src="@/assets/images/home/gysm7.1_left.svg" alt />
      </div>
      <div class="time-text">
        <div
          v-for="(item, index) in timeArr"
          :key="index"
          style="position: relative"
        >
          <div
            :class="[
              'time-display',
              item.t.includes('今天') ? 'active-time' : '',
            ]"
          >
            {{ item.t }}
          </div>
        </div>
      </div>
      <div
        style="margin-right: 48px"
        class="time-img"
        @click="changeTimeList(3)"
      >
        <img src="@/assets/images/home/gysm7.1_right.svg" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      date: null,
      index: 0,
      timeArr: [],
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {
    this.date = new Date();
  },
  mounted() {},
  methods: {
    //回到当前时间
    returnFun() {
      this.changeTimeList(0);
    },
    //切换前进后退按钮
    changeTimeList(num) {
      this.index = 0;
      if (num) {
        this.date = new Date(
          new Date(this.timeArr[0].t.slice(0, 10)).getTime() +
            1000 * 60 * 60 * 24 * num
        );
        this.timeArr = [];
        this.getTimeArr(false);
      } else if (num == 0) {
        this.date = new Date();
        this.timeArr = [];
        this.getTimeArr(false);
      }
      let first = new Date(
        new Date(this.timeArr[0].t.slice(0, 10)).setHours(0, 0, 0, 0)
      ).getTime();
      let last = new Date(
        new Date(this.timeArr[2].t.slice(0, 10)).setHours(0, 0, 0, 0) +
          24 * 60 * 60 * 1000 -
          1
      ).getTime();
      this.$emit("getTimeFun", [first, last]);
    },
    //获取时间数据
    getTimeArr(bool = true) {
      let date = this.date;
      if (this.index < 3) {
        let computed =
          this.index === 0
            ? date.getTime() + 1000 * 60 * 60 * 24 * this.index
            : new Date(this.timeArr[0].t.slice(0, 10)).getTime() +
              1000 * 60 * 60 * 24 * this.index;
        date.setTime(computed);
        let time = this.handleTime(date);
        this.timeArr.push({ t: time });
        this.index++;
        this.getTimeArr(bool); //递归
      } else {
        //处理今天明天后天的文字显示问题
        let today = this.handleTime(new Date());
        if (
          new Date(this.timeArr[0].t.slice(0, 10)).getTime() ==
          new Date(today.slice(0, 10)).getTime()
        ) {
          this.timeArr[0].t = this.timeArr[0].t.slice(0, 10) + "（今天）";
          this.timeArr[1].t = this.timeArr[1].t.slice(0, 10) + "（明天）";
          this.timeArr[2].t = this.timeArr[2].t.slice(0, 10) + "（后天）";
        }
        if (bool) this.changeTimeList();
        return;
      }
    },
    //处理时间
    handleTime(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let week = date.getDay();
      switch (week) {
        case 0:
          week = "日";
          break;
        case 1:
          week = "一";
          break;
        case 2:
          week = "二";
          break;
        case 3:
          week = "三";
          break;
        case 4:
          week = "四";
          break;
        case 5:
          week = "五";
          break;
        default:
          week = "六";
      }
      month = month > 9 ? month : "0" + month;
      day = day < 10 ? "0" + day : day;
      let time = year + "-" + month + "-" + day + "星期" + week;
      return time;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-time-page {
  height: 100%;
  display: flex;
  .return-today {
    width: 87px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .today {
      width: 42px;
      height: 42px;
      box-shadow: 0px 0px 4px 0px #00000031;
      border-radius: 50%;
      color: #336ffe;
      font-size: 14px;
      text-align: center;
      line-height: 42px;
      border: 1px solid #336ffe;
      cursor: pointer;
    }
  }
  .time {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .time-text {
      width: calc(100% - 250px);
      display: flex;
      justify-content: space-around;
      .time-display {
        width: 161px;
        height: 40px;
        border-radius: 30px;
        text-align: center;
        line-height: 40px;
      }
      .active-time {
        color: #336ffe;
        background: #336ffe30;
      }
    }
    .time-img {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      opacity: 1;
      cursor: pointer;
    }
  }
}
</style>
