<template>
  <div class="reserve_form">
    <div class="manual-content">
      <div class="header">
        <div class="text">预留车票</div>
        <div class="close" @click="closeFun"></div>
      </div>
      <div class="content-6">
        <el-form
          ref="reserveForm"
          label-width="100px"
          :model="reserve"
          :rules="reserveRules"
          class="reserveForm"
        >
          <el-row style="padding: 20px">
            <h4>预留班次信息</h4>
            <el-col :span="12">
              <el-form-item label="出发地：" prop="startStation">
                <el-select
                  v-if="startStation && reserveEditable"
                  v-model="reserve.startStation"
                >
                  <el-option
                    v-for="(item, index) in startStation"
                    :key="index"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <el-input
                  v-else
                  placeholder="出发地"
                  disabled
                  v-model="reserve.startStation"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="到达地：" prop="endStation">
                <el-select
                  v-if="endStation && reserveEditable"
                  v-model="reserve.endStation"
                >
                  <el-option
                    v-for="(item, index) in endStation"
                    :key="index"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <el-input
                  v-else
                  disabled
                  placeholder="到达地"
                  v-model="reserve.endStation"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出行日期：" prop="departureDate">
                <el-date-picker
                  v-model="reserve.departureDate"
                  :disabled="!reserveEditable"
                  placeholder="出行日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班次时间：" prop="departureTime">
                <!-- <el-input placeholder="班次时间"></el-input> -->
                <el-select
                  v-if="reserveEditable"
                  v-model="reserve.departureId"
                  :loading="Times_loading"
                  placeholder="请选择班次"
                >
                  <el-option
                    v-for="(item, index) in workForceTimes"
                    :key="index"
                    :value="item.id"
                    :label="item.departureTime + '- 余票' + item.surplusTicket"
                  ></el-option>
                </el-select>
                <el-input v-else v-model="time_ticket" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上车点：">
                <el-input
                  v-model="reserve.startAddress"
                  :readonly="true"
                  placeholder="请选择上车点"
                  style="width: 88%"
                />
                <i class="iconfont its_gy_map" @click="selectAddress(0)"></i>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="下车点：">
                <el-input
                  v-model="reserve.endAddress"
                  :readonly="true"
                  placeholder="请选择下车点"
                  style="width: 88%"
                />
                <i class="iconfont its_gy_map" @click="selectAddress(1)"></i>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预留数量：" prop="reservedTicket">
                <el-input-number
                  v-model="reserve.reservedTicket"
                  :min="0"
                  :max="reserveSurplusTicket"
                  size="small"
                  @change="changeReservedTicket"
                ></el-input-number>
              </el-form-item>
              <p v-if="show" class="sign">
                若要继续减少预留数量，请先减少携童数
              </p>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="携童数："
                prop="carryingChildrenNum"
                style="margin-bottom: 0"
              >
                <el-input-number
                  :min="0"
                  :max="
                    children >= reserve.reservedTicket
                      ? reserve.reservedTicket
                      : children
                  "
                  v-model="reserve.carryingChildrenNum"
                  size="small"
                  @change="changeReservedTicket"
                ></el-input-number>
                <div>
                  <span style="color: gray">
                    此班次剩余携童票
                    <span style="color: red">{{ children }}</span> 张
                  </span>
                </div>
              </el-form-item>
            </el-col>
            <h4>乘客信息</h4>
            <el-col :span="12">
              <el-form-item label="预留人：">
                <el-input
                  placeholder="请填写预留人姓名"
                  v-model="reserve.reservedTicketPassenger"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预留手机号：" prop="reservedTicketPhone">
                <el-input
                  placeholder="请填写预留手机号"
                  v-model="reserve.reservedTicketPhone"
                  maxlength="13"
                  @blur="setPassword"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预留密码：" prop="reservedTicketPassword">
                <el-input
                  v-model="reserve.reservedTicketPassword"
                  placeholder="请填写预留密码"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注：">
                <el-input
                  v-model="reserve.remark"
                  type="textarea"
                  maxlength="30"
                  rows="4"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="footer">
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button @click="closeFun">取消</el-button>
      </div>
    </div>
    <el-dialog
      v-if="mapDialog"
      :title="areaType ? '选择下车点' : '选择上车点'"
      :visible="mapDialog"
      width="1000px"
      :close-on-click-modal="false"
      :modal="false"
      @close="cancelBtn"
    >
      <div class="map">
        <div v-if="areaMode == 0" class="searchbox">
          <header>
            <input v-model="searchInput" type="text" placeholder="搜索地址" />
            <el-button type="primary" size="small">搜索</el-button>
          </header>
          <div class="pointlist">
            <p
              v-for="(item, index) in addressList"
              :key="index"
              @click="searchClick(item)"
            >
              {{ item.address }}
            </p>
          </div>
        </div>
        <div class="select" :style="{ top: areaMode == 0 ? '55px' : '14px' }">
          <header>请选择地点</header>
          <p v-if="areaMode == 0">{{ point.address }}</p>
          <div v-if="areaMode == 1" class="point">
            <div
              v-for="(item, index) in pointList"
              :key="index"
              class="item"
              :class="{ item1: currentIndex === index }"
              @click="selectPoint(item, index)"
            >
              <span>{{ item.name }}</span>
              <span class="detial" :title="item.address">
                {{ item.address }}
              </span>
            </div>
          </div>
        </div>
        <div id="container" />
      </div>
      <span v-cloak slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="cancelBtn"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="confirm">选择</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkPhone } from "@/common/utils/index.js";
import {
  addReserveTicket,
  queryIntercityLineStationRelationPage,
  intercityPageAPI,
  queryAreaList,
  searchPointAPI,
} from "@/api/lib/api.js";
import axios from "axios";
export default {
  props: ["startStation", "endStation", "rate"],
  data() {
    return {
      stationIdList: [], //途经点数据
      current: new Date(),
      reserveEditable: true,
      reserveSurplusTicket: 0,
      shiftIntercityMap: null,
      workForceTimes: [],
      Times_loading: false,
      reserve: {
        startStation: "",
        endStation: "",
        carryingChildrenNum: 0,
        reservedTicket: 0,
        shiftIntercityId: null,
        departureTime: null,
        stationId: null,
        startAreaId: null,
        startPointLatitude: "",
        startPointLongitude: "",
        startAddress: "",
        endAreaId: null,
        endPointLatitude: "",
        endPointLongitude: "",
        endAddress: "",
      },
      reserveRules: {
        startStation: [
          { required: true, message: "出发地不能为空", trigger: "change" },
        ],
        endStation: [
          { required: true, message: "到达地不能为空", trigger: "change" },
        ],
        departureDate: [
          { required: true, message: "出行日期不能为空", trigger: "change" },
        ],
        departureTime: [
          { required: true, message: "班次时间不能为空", trigger: "change" },
        ],
        reservedTicket: [
          { required: true, message: "预留数不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value > 0) return callback();
              return callback(new Error("至少预留一张票"));
            },
            trigger: "blur",
          },
        ],
        reservedTicketPhone: [
          { required: true, message: "预留电话不能为空", trigger: "change" },
          { validator: checkPhone, trigger: "blur" },
        ],
        reservedTicketPassword: [
          { required: true, message: "预留密码不能为空", trigger: "change" },
        ],
      },
      children: 0,
      ticketNum: 0,
      show: false,
      areaType: null,
      lineId: null,
      mapDialog: false,
      map: null,
      marker: null,
      areaMode: null,
      searchInput: "",
      addressList: [],
      pointList: [],
      currentIndex: -1,
      areaPath: [],
      pickerPosition: null,
      positionLatLng: null,
      point: {
        address: "",
        startPointLatitude: "",
        startPointLongitude: "",
        startAddress: "",
        startAreaId: null,
        endPointLatitude: "",
        endPointLongitude: "",
        endAddress: "",
        endAreaId: null,
        stationId: null,
      },
    };
  },
  computed: {
    time_ticket: function () {
      return `${this.reserve.departureTime} - 余票${this.reserveSurplusTicket}`;
    },
  },
  watch: {
    searchInput: {
      handler(val) {
        if (val) {
          this.searchFun(val);
        } else {
          this.addressList = [];
        }
      },
    },
  },
  methods: {
    //初始化预留票表单
    loadInfo(info) {
      if (!info) {
        this.reserveEditable = true;
        this.reserve = {};
      } else {
        this.reserveEditable = false;
        this.$set(this.reserve, "stationId", ""); //重置途经点
        this.reserve.reservedTicket = 0; //重置预留数量
        this.$set(this.reserve, "reservedTicketPassenger", ""); //重置预留人
        this.$set(this.reserve, "reservedTicketPhone", ""); //重置预留手机号
        this.$set(this.reserve, "reservedTicketPassword", ""); //重置预留密码
        this.$set(this.reserve, "remark", ""); //重置备注
        this.reserve.carryingChildrenNum = 0;
        this.setReserve(info);
        this.show = false;
        this.ticketNum = info.ticketNum;
        this.children = info.surplusCarryingChildrenNum;
        this.lineId = info.lineId;
      }
      this.$nextTick(() => {
        this.$refs.reserveForm.clearValidate();
      });
      //查询途经点数据
      queryIntercityLineStationRelationPage({
        lineId: info.lineId,
        lineStationType: 2,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.stationIdList = res.data.list?.filter((item) => {
            return item.lineStationStatus === 0;
          });
        }
      });
    },
    changeReservedTicket(cur, old) {
      if (cur < old && old == this.reserve.carryingChildrenNum) {
        this.show = true;
        this.$nextTick(() => {
          this.reserve.reservedTicket = this.reserve.carryingChildrenNum;
        });
      }
      if (cur > old) this.show = false;
      if (this.reserve.carryingChildrenNum < this.reserve.reservedTicket)
        this.showTip = false;
    },
    // 填写表单数据;
    setReserve(info) {
      this.reserveSurplusTicket = info.surplusTicket;
      this.reserve.shiftIntercityId = info.id;
      this.reserve.vehicleShiftId = info.vehicleShiftId;
      this.reserve.vehicleShiftName = info.vehicleShiftName;
      this.reserve.status = 0;
      this.reserve.lineId = info.lineId;
      this.reserve.lineName = info.lineName;
      this.reserve.departureDate = info.departureDate;
      this.reserve.departureTime = info.departureTime;
      this.reserve.companyName = info.companyName;
      this.reserve.companyId = info.companyId;
      this.reserve.startStation = info.startStation;
      this.reserve.endStation = info.endStation;
    },
    // 自动填写预留密码
    setPassword() {
      let phone = this.reserve.reservedTicketPhone;
      if (phone && phone.length >= 11) {
        this.$set(
          this.reserve,
          "reservedTicketPassword",
          phone.substr(phone.length - 4)
        );
      }
    },
    submit() {
      this.$refs.reserveForm?.validate((valid) => {
        if (valid) {
          // this.reserve.departureTime =
          //   this.reserve.departureTime.split("-")[0];
          this.reserve.departureDateTime = `${
            this.reserve.departureDate.split(" ")[0]
          } ${this.reserve.departureTime}:00`;
          this.reserve.stationId = this.reserve.stationId || null;
          this.reserve.ticketNum = this.ticketNum;
          addReserveTicket(this.reserve).then((res) => {
            this.$message.success("预留成功");
            this.$emit("saveOk");
          });
        }
      });
    },
    // 地点选择
    selectAddress(type) {
      this.areaType = type;
      if (this.reserve.shiftIntercityId) {
        this.mapDialog = true;
        this.$nextTick(() => {
          this.loadmap(type);
        });
      } else {
        this.$message.warning("请先选择班次时间");
      }
    },
    // 实例化地图
    loadmap(type) {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.marker = null;
      const area = {
        lineId: this.lineId,
        areaType: type,
        shiftIntercityId: this.reserve.shiftIntercityId,
      };
      queryAreaList(area).then((res) => {
        if (res.code == "SUCCESS") {
          const data = res.data;
          data.forEach((item) => {
            let arr = JSON.parse(item.areaContent);
            if (item.areaMode == 0) {
              this.areaMode = 0;
              let path = [];
              arr.forEach((e) => {
                path.push([e.lng, e.lat, item.id]);
                this.areaPath.push(item);
              });
              //初始化polygon
              var polygon = new AMap.Polygon({
                path: path,
                strokeColor: "#FF33FF",
                strokeWeight: 6,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                fillColor: "#1791fc",
                zIndex: 50,
              });
              this.map.add(polygon);
            } else {
              this.areaMode = 1;
              let obj = arr[0];
              let flag = new AMap.Marker({
                position: [obj.lng, obj.lat],
              });
              let markerTxt = new AMap.Text({
                text: item.name + "——" + item.detialAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: [obj.lng, obj.lat],
              });
              markerTxt.setMap(this.map);
              this.map.add(flag);
              this.pointList.push({
                name: item.name,
                address: item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                type: area.areaType,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
              });
            }
            this.map.setFitView();
          });
          if (!this.areaMode) this.positionPicker(area.areaType);
        }
      });
    },
    positionPicker(type, item) {
      let vm = this;
      this.map.on("drag", function () {
        vm.marker && vm.marker.hide();
      });
      this.map.on("dragend", function () {
        vm.marker && vm.marker.show();
      });
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          iconStyle: {
            url: require("@/assets/images/home/from_icon2.png"),
            size: [30, 39],
            ancher: [15, 30], //锚点的位置，即被size缩放之后，图片的什么位置作为选中的位置
          },
          zIndex: 666,
        });
        vm.pickerPosition.on("success", function (positionResult) {
          const pos = positionResult.position;
          if (type == 0) {
            vm.point.startPointLatitude = positionResult.position.lat;
            vm.point.startPointLongitude = positionResult.position.lng;
            new Promise((resolve, reject) => {
              //成功的时候调用resolve
              resolve(vm.regeoFun(positionResult));
              //失败的时候调用reject
              reject("error message");
            }).then((data) => {
              //处理成功后的逻辑
              vm.point.startAddress = data;
            });
          } else {
            vm.point.endPointLatitude = positionResult.position.lat;
            vm.point.endPointLongitude = positionResult.position.lng;
            new Promise((resolve, reject) => {
              //成功的时候调用resolve
              resolve(vm.regeoFun(positionResult));
              //失败的时候调用reject
              reject("error message");
            }).then((data) => {
              //处理成功后的逻辑
              vm.point.endAddress = data;
            });
          }
          vm.point.address = positionResult.address;
          vm.positionLatLng = [
            positionResult.position.lng,
            positionResult.position.lat,
          ];
          if (!vm.marker) {
            vm.marker = new AMap.Text({
              text: positionResult.address,
              zIndex: 777,
              anchor: "center",
              offset: new AMap.Pixel(0, -60),
              style: {
                "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                padding: "14px",
              },
              position: vm.positionLatLng,
            });
            vm.marker.setMap(vm.map);
          } else {
            vm.marker.setPosition(vm.positionLatLng);
            vm.marker.setText(positionResult.address);
          }
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    async regeoFun(positionResult) {
      const res = await axios
        .get(
          `https://restapi.amap.com/v3/geocode/regeo?output=json&location=${positionResult.position.lng},${positionResult.position.lat}&key=d85b28ec7a166619a217cfe7cc0d700b&radius=1000&extensions=all`
        )
        .then((res) => {
          const addressComponent = res.data.regeocode.addressComponent;
          const abbreviation =
            res.data.regeocode.aois[0]?.name || addressComponent.district; //简称
          const detailedAddress =
            addressComponent.province +
            addressComponent.city +
            addressComponent.district +
            addressComponent.streetNumber.street +
            addressComponent.streetNumber.number; //详细地址
          return abbreviation + "|" + detailedAddress;
        });
      return res;
    },
    // 点击搜索的地址
    searchClick(item) {
      this.positionLatLng = [item.wgLon, item.wgLat];
      this.map.setZoom(16);
      this.map.setCenter(this.positionLatLng);
      this.addressList = [];
      this.searchInput = "";
      this.point.address = item.address;
    },
    // 输入地名搜索地址
    searchFun(val) {
      const obj = {
        searchKey: val,
        lineId: this.lineId,
        areaType: this.areaType,
        shiftId: this.reserve.shiftIntercityId,
      };
      searchPointAPI(obj).then((res) => {
        this.addressList = res.data;
      });
    },
    // 选择固定点
    selectPoint(item, index) {
      this.currentIndex = index;
      this.positionLatLng = [item.lng, item.lat];
      this.map.setCenter(this.positionLatLng);
      if (item.type == 0) {
        this.point.startPointLatitude = item.lat;
        this.point.startPointLongitude = item.lng;
        this.point.startAddress = item.name + "|" + item.address;
        this.point.startAreaId = item.id;
      } else {
        this.point.endPointLatitude = item.lat;
        this.point.endPointLongitude = item.lng;
        this.point.endAddress = item.name + "|" + item.address;
        this.point.endAreaId = item.id;
        if (item.areaType == 2) {
          this.point.stationId = item.stationId;
        } else {
          this.point.stationId = null;
        }
      }
    },
    // 确认上车点下车点
    confirm() {
      if (this.areaMode == 0) {
        let inRing = this.areaPath.findIndex((item) => {
          const arr = JSON.parse(item.areaContent);
          let path = [];
          arr.forEach((e) => path.push([e.lng, e.lat]));
          return AMap.GeometryUtil.isPointInRing(this.positionLatLng, path);
        });

        if (inRing > -1) {
          if (this.areaType === 0) {
            this.reserve.startPointLatitude = this.point.startPointLatitude;
            this.reserve.startPointLongitude = this.point.startPointLongitude;
            this.reserve.startAddress = this.point.startAddress;
            this.reserve.startAreaId = this.areaPath[inRing].id;
          } else {
            this.reserve.endPointLatitude = this.point.endPointLatitude;
            this.reserve.endPointLongitude = this.point.endPointLongitude;
            this.reserve.endAddress = this.point.endAddress;
            this.reserve.endAreaId = this.areaPath[inRing].id;
            if (this.areaPath[inRing].areaType == 2) {
              this.reserve.stationId = this.areaPath[inRing].stationId;
            } else {
              this.reserve.stationId = null;
            }
          }
          this.cancelBtn();
        } else {
          this.marker && this.marker.hide();
          this.$message.warning("该地点不在可选区域内！");
        }
      } else {
        if (this.positionLatLng) {
          if (this.areaType == 0) {
            this.reserve.startPointLatitude = this.point.startPointLatitude;
            this.reserve.startPointLongitude = this.point.startPointLongitude;
            this.reserve.startAddress = this.point.startAddress;
            this.reserve.startAreaId = this.point.startAreaId;
          } else {
            this.reserve.endPointLatitude = this.point.endPointLatitude;
            this.reserve.endPointLongitude = this.point.endPointLongitude;
            this.reserve.endAddress = this.point.endAddress;
            this.reserve.endAreaId = this.point.endAreaId;
            this.reserve.stationId = this.point.stationId;
          }
          this.cancelBtn();
        } else {
          this.$message.warning("请选择固定点！");
        }
      }
      this.marker = null;
    },
    cancelBtn() {
      this.mapDialog = false;
      this.marker = null;
      this.areaPath = [];
      this.addressList = [];
      this.searchInput = "";
      this.positionLatLng = null;
      this.pointList = [];
      this.currentIndex = -1;
      if (this.map) this.map.destroy();
    },
    closeFun() {
      this.$emit("closePopupFun");
    },
  },
};
</script>

<style lang="scss" scoped>
.reserve_form {
  z-index: 777;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .manual-content {
    width: 800px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .header {
      padding: 18px 24px;
      height: 24px;
      border-bottom: 1px solid #d7d7d7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text {
        margin-top: 8px;
      }
      .close {
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("../../../../assets/images/home/close.png") no-repeat;
        background-size: 100%;
      }
    }
    .footer {
      padding: 10px 24px 18px 24px;
      height: 32px;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
    }
  }
}
.reserveForm {
  .el-textarea {
    width: 100%;
  }
  .el-input {
    width: 200px;
  }
  .el-select {
    width: 200px;
  }
  i {
    color: #336ffe;
    margin-left: 10px;
  }
  .sign {
    color: red;
    margin-top: -22px;
    margin-left: 30px;
    margin-bottom: 6px;
  }
}
/deep/ .el-textarea textarea {
  resize: none !important;
  height: 100px;
}
.map {
  position: relative;
  height: 500px;
  .searchbox {
    position: absolute;
    top: 14px;
    left: 10px;
    width: 300px;
    z-index: 1200;
    input {
      width: 212px;
      height: 28px;
      border: #d6d6d6 solid 1px;
      flex: 1;
      outline: none;
      margin-right: 16px;
      padding-left: 10px;
    }
    .pointlist {
      max-height: 250px;
      background-color: #fff;
      overflow: auto;
      margin-top: 10px;
      p {
        padding: 7px 10px;
        cursor: pointer;
        &:hover {
          background-color: #e4e4e4;
        }
      }
    }
  }
  .select {
    width: 312px;
    position: absolute;
    z-index: 1100;
    left: 10px;
    background-color: #fff;
    border-radius: 5px;
    header {
      font-size: 15px;
      color: black;
      padding: 10px 10px 0;
    }
    p {
      margin-top: 10px;
      padding: 0 10px 10px;
    }
    .point {
      margin: 10px 0;
      .item {
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 10px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        .detial {
          margin-left: 15px;
          font-size: 12px;
        }
      }
      .item1 {
        color: white;
        background-color: #336ffe;
      }
    }
  }
  #container {
    height: 500px;
  }
}
</style>
