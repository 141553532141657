<template>
  <!-- 调度任务管理 -->
  <div class="dispatchManage">
    <!-- 左侧线路菜单栏 -->
    <div class="menuBar">
      <el-tree
        node-key="lineCode"
        ref="tree"
        :expand-on-click-node="false"
        :highlight-current="true"
        :data="menuData"
        :props="menuDefaultProps"
        @node-click="handleNodeClick"
      >
        <span class="span-ellipsis" slot-scope="{ node, data }">
          <i v-if="data.lineCode" class="iconfont its_sm_luxian"></i>
          <span :title="node.label" class="menuLabel">{{ node.label }}</span>
        </span>
      </el-tree>
    </div>
    <!-- 右侧工作区 -->
    <div class="dispatch-content">
      <!-- 顶部日期模块 -->
      <div class="dispatch-date">
        <carouselTime
          ref="carouselTimeRef"
          @getTimeFun="getTimeFun"
        ></carouselTime>
      </div>
      <!-- 管理块 -->
      <div v-if="renderData.length > 0" ref="dispatchBox" class="dispatch-box">
        <!-- 某个时间点的一行 -->
        <div class="time-row" v-for="item in renderData" :key="item.time">
          <div class="time">
            <div>
              <p class="time-result">{{ item.time }}</p>
              <p class="time-text">
                <span :class="shiftType(item.type, 'class')"></span>
                {{ shiftType(item.type, "type") }}
              </p>
            </div>
          </div>
          <!-- 块 -->
          <div class="dis-block">
            <!-- 内容 -->
            <div
              class="area"
              v-for="(jump, index) in item.scheduleTaskVO"
              :key="index"
            >
              <div
                v-if="jump"
                class="area-box"
                :class="colorManageFun(jump.ticketShiftIntercityStatus, 'box')"
              >
                <!-- 左 -->
                <div
                  class="area-box-left"
                  :class="
                    colorManageFun(jump.ticketShiftIntercityStatus, 'son')
                  "
                >
                  <p class="statusLabel">
                    {{ ticketShiftStatus(jump.ticketShiftIntercityStatus) }}
                  </p>
                  <p class="statusPoll">
                    {{ jump.totalSoldTicketNum }}/{{ jump.totalTicketNum }}
                  </p>
                </div>
                <!-- 右 -->
                <div class="area-box-right">
                  <div
                    class="area-shift"
                    v-for="project in jump.scheduleTaskPOS"
                    :key="project.id"
                    @click.stop="openMenu($event, project, jump)"
                  >
                    <div class="shift-box">
                      <div
                        class="shift-status"
                        :class="
                          orderStatusFun(
                            project.orderStatus,
                            'class',
                            project.ticketShiftIntercityStatus
                          )
                        "
                      >
                        {{
                          orderStatusFun(
                            project.orderStatus,
                            "text",
                            project.ticketShiftIntercityStatus
                          )
                        }}
                      </div>
                      <div class="shift-info">
                        <div class="shift-address">
                          {{ project.lineName }}
                          <i
                            v-if="
                              project.orderStatus == 40 ||
                              project.orderStatus == 31
                                ? false
                                : true
                            "
                            class="iconfont its_menu"
                          ></i>
                        </div>
                        <div class="shift-drivers">
                          <span v-show="project.orderStatus !== 0">
                            {{ project.licensePlateNumber }}
                            {{ project.driverName ? project.driverName : "" }}
                          </span>
                          <div>
                            <span>{{ project.reservedTicket }}</span
                            >/
                            <span>{{ project.soldTicketNum }}</span>
                            /{{ project.surplusTicket }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="dispatch-box">
        <div class="defaultPage">
          <p>暂无调度班次</p>
          <p><span>请稍后再试，</span>或者切换其他的线路试试</p>
        </div>
      </div>
    </div>
    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li v-for="item in menuBox" :key="item.text" @click="menuFun(item)">
        <i :class="item.class"></i>
        {{ item.text }}
      </li>
    </ul>
    <!----------------------------------- 弹窗 ---------------------------------------->
    <div v-if="popupShow && popupShow != 'reserve'" class="operationPopup">
      <!-- content容器 -->
      <div class="manual-content">
        <!-- header -->
        <div class="header">
          <div class="text">{{ headerText }}</div>
          <div class="close" @click="closeFun"></div>
        </div>
        <!-- content -->
        <div
          v-if="popupShow == 'add' || popupShow == 'reassignment'"
          class="content"
        >
          <el-form
            :inline="true"
            :model="sendMoreForm"
            class="demo-form-inline"
            label-position="left"
            label-width="80px"
          >
            <h2 v-if="popupShow == 'reassignment'">原调度任务</h2>
            <el-form-item label="线路：">
              <p class="form-label">
                {{ popupObj.startStation }}-{{ popupObj.endStation }}
              </p>
            </el-form-item>
            <el-form-item label="发车时间：">
              <p class="form-label">{{ popupObj.startDepartureDateTime }}</p>
            </el-form-item>
            <el-form-item label="车辆：">
              <el-select
                v-model="sendMoreForm.vehicleId"
                clearable
                placeholder="请选择车辆"
                :disabled="disabled"
                @change="carListChange"
              >
                <el-option
                  v-for="role in carList"
                  :key="role.id"
                  :label="
                    role.brand +
                    '-' +
                    role.licensePlateNumber +
                    '-' +
                    role.seatNumber +
                    '座'
                  "
                  :value="role.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="司机：">
              <el-select
                v-model="sendMoreForm.driverId"
                clearable
                placeholder="请选择司机"
                :disabled="disabled"
              >
                <el-option
                  v-for="role in driverList"
                  :key="role.driverId"
                  :label="role.driverName + '-' + role.driverPhone"
                  :value="role.driverId"
                ></el-option>
              </el-select>
            </el-form-item>
            <h2 v-if="popupShow == 'reassignment'">改派后调度任务</h2>
            <div v-if="popupShow == 'reassignment'">
              <el-form-item label="车辆：">
                <el-select
                  v-model="reassignmentAfterObj.vehicleId"
                  clearable
                  placeholder="请选择车辆"
                  @change="carListChange"
                >
                  <el-option
                    v-for="role in reassignmentCarList"
                    :key="role.id"
                    :label="
                      role.brand +
                      '-' +
                      role.licensePlateNumber +
                      '-' +
                      role.seatNumber +
                      '座'
                    "
                    :value="role.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="司机：">
                <el-select
                  v-model="reassignmentAfterObj.driverId"
                  clearable
                  placeholder="请选择司机"
                >
                  <el-option
                    v-for="role in reassignmentDriverList"
                    :key="role.driverId"
                    :label="role.driverName + '-' + role.driverPhone"
                    :value="role.driverId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 取消任务 -->
        <div v-if="popupShow == 'cancel'" class="content">
          <p class="pureText">取消任务后，系统将不会进行自动调度！是否继续？</p>
        </div>
        <!-- 暂停班次 -->
        <div v-if="popupShow == 'suspend'" class="content">
          <p class="pureText">
            暂停班次后，乘客将无法购买该班次的车票！是否继续？
          </p>
        </div>
        <!-- 启售班次 -->
        <div v-if="popupShow == 'enable'" class="content">
          <p class="pureText">是否要启售班次？</p>
        </div>
        <!-- 快速派单 -->
        <div v-if="popupShow == 'quicklysend'" class="content">
          <p class="pureText">派单后，司机会收到派单通知，可发车接送乘客</p>
        </div>
        <!-- footer -->
        <div class="footer">
          <div>
            <el-button @click="confirmFun">{{
              popupShow == "quicklysend" ? "确认派单" : "确认"
            }}</el-button>
            <el-button @click="closeFun">取消</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 预留 -->
    <reserved-form
      v-if="showReserved"
      ref="reserved_form"
      :rate="rate"
      @closePopupFun="showReserved = false"
      @saveOk="reservedCallback"
    />
  </div>
</template>

<script>
import {
  queryShiftIntercityScheduleMainLineTreeAPI,
  queryScheduleTasksAPI,
  getAllVehicleList,
  saveShiftIntercityScheduleAPI,
  deleteShiftIntercityScheduleByIdAPI,
  shiftIntercityAPI,
  updateShiftIntercityScheduleAPI,
  driverIntercityAPI,
  ticketRegulationAPI,
  quickScheduleTask,
} from "@/api/lib/api.js";
import carouselTime from "@/components/carouselTime/carouselTime";
import reservedForm from "../vehicleManage/workforcePopup/reservedForm.vue";
export default {
  components: {
    carouselTime,
    reservedForm,
  },
  data() {
    return {
      disabled: false,
      showReserved: false,
      inputNumberMax: 0, //最大预留票数
      reserveNumTextarea: "",
      reserveNum: 0,
      // menuBoxbackups: [
      //   {
      //     text: "增派任务",
      //     class: "iconfont its_zengpaibanci",
      //     switch: "add"
      //   },
      //   {
      //     text: "改派任务",
      //     class: "iconfont its_gaipai",
      //     switch: "reassignment"
      //   },
      //   {
      //     text: "取消任务",
      //     class: "iconfont its_quxiao1",
      //     switch: "cancel"
      //   },
      //   {
      //     text: "电召下单",
      //     class: "iconfont its_yuliuchepiao",
      //     switch: "reserve"
      //   },
      //   {
      //     text: "暂停班次",
      //     class: "iconfont its_zantingbanci",
      //     switch: "suspend"
      //   },
      //   {
      //     text: "启售班次",
      //     class: "iconfont its_qidongbanci",
      //     switch: "enable"
      //   }
      // ],
      menuBox: [],
      sendMoreForm: {
        vehicleId: "", //车辆id
        driverId: "", // 司机id
      },
      visible: false,
      top: 0,
      left: 0,
      menuData: [],
      renderData: [],
      menuDefaultProps: {
        children: "childLines",
        label: "lineName",
      },
      startTime: "",
      endTime: "",
      lineIds: "",
      popupShow: null,
      carList: [], //可调度车辆列表
      driverList: [], //可调度司机列表
      reassignmentCarList: [],
      reassignmentDriverList: [],
      headerText: "",
      popupObj: {},
      vehicleId: null,
      firstOnoff: true,
      reassignmentAfterObj: {
        vehicleId: "", //车辆id
        driverId: "", // 司机id
      }, //改派后存储的车辆 + 司机数据
      reserveRadio: "1",
      fff: false,
      id: null,
      rate: 0,
    };
  },
  watch: {
    reserveRadio(val) {
      this.reserveNum = 0;
      if (val == 2) {
        this.inputNumberMax = this.popupObj.reservedTicket;
      } else {
        this.inputNumberMax = this.popupObj.surplusTicket;
      }
    },
  },
  mounted() {
    this.$refs.carouselTimeRef.getTimeArr();
    this.renderTreeFun();
    this.renderDataA();
    document.body.addEventListener("click", this.closeMenu);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.closeMenu);
  },
  methods: {
    // 购票规则数据获取
    renderDataA() {
      ticketRegulationAPI({ channelNo: this.$configs.channelNo }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            const value = res.data.find(
              (e) => e.regulationCode == "isCarryingChildren"
            ).regulationValue;
            this.rate = value / 100;
          }
        }
      );
    },
    // 增派任务API
    saveShiftIntercityScheduleFun() {
      if (!this.sendMoreForm.vehicleId) {
        this.$message.error("请选择车辆");
        return;
      }
      if (!this.sendMoreForm.driverId) {
        this.$message.error("请选择司机");
        return;
      }
      let obj = {};
      // 车辆
      for (let i = 0; i < this.carList.length; i++) {
        if (this.carList[i].id == this.sendMoreForm.vehicleId) {
          obj.vehicleId = this.carList[i].id;
          obj.vehicleColorStr = this.carList[i].brand;
          obj.licensePlateNumber = this.carList[i].licensePlateNumber;
          obj.vehicleBrand = this.carList[i].brand;
          obj.vehicleSeatNumber = this.carList[i].seatNumber;
          break;
        }
      }
      for (let i = 0; i < this.driverList.length; i++) {
        if (this.driverList[i].driverId == this.sendMoreForm.driverId) {
          obj.driverId = this.driverList[i].driverId;
          obj.driverName = this.driverList[i].driverName;
          obj.driverPhone = this.driverList[i].driverPhone;
          break;
        }
      }
      obj.id = this.popupObj.id;
      obj.departureDateTime = new Date(
        this.popupObj.startDepartureDateTime
      ).getTime();
      saveShiftIntercityScheduleAPI(obj).then((res) => {
        if (res.code === "SUCCESS") {
          this.renderDataFun();
          this.closeFun();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 车辆下拉框change事件
    carListChange(val) {
      if (val) {
        this.queryDriverFun(val);
      } else {
        if (this.popupShow == "add") {
          this.sendMoreForm.driverId = "";
          this.driverList = [];
        } else if (this.popupShow == "reassignment") {
          this.reassignmentDriverList = [];
          this.reassignmentAfterObj.driverId = "";
        }
      }
    },
    // 弹窗确定按钮
    confirmFun() {
      // 增派
      if (this.popupShow == "add") {
        this.saveShiftIntercityScheduleFun();
      }
      // 改派
      else if (this.popupShow == "reassignment") {
        this.updateShiftIntercityScheduleFun();
      }
      // 预留
      else if (this.popupShow == "reserve") {
        this.reserveFun();
      }
      // 暂停
      else if (this.popupShow == "suspend") {
        this.suspendFun(2);
      }
      // 启售
      else if (this.popupShow == "enable") {
        this.suspendFun(3);
      }
      // 取消
      else if (this.popupShow == "cancel") {
        this.deleteShiftIntercityScheduleByIdFun();
      }
      // 快速派单
      else if (this.popupShow == "quicklysend") {
        this.sendOrderQuickly();
      }
    },
    // 改派任务
    updateShiftIntercityScheduleFun() {
      if (!this.reassignmentAfterObj.vehicleId) {
        this.$message.error("请选择车辆");
        return;
      }
      if (!this.reassignmentAfterObj.driverId) {
        this.$message.error("请选择司机");
        return;
      }
      let obj = {};
      // 车辆
      for (let i = 0; i < this.reassignmentCarList.length; i++) {
        if (
          this.reassignmentCarList[i].id == this.reassignmentAfterObj.vehicleId
        ) {
          obj.vehicleId = this.reassignmentCarList[i].id;
          obj.vehicleColorStr = this.reassignmentCarList[i].brand;
          obj.licensePlateNumber =
            this.reassignmentCarList[i].licensePlateNumber;
          obj.vehicleBrand = this.reassignmentCarList[i].brand;
          obj.vehicleSeatNumber = this.reassignmentCarList[i].seatNumber;
          break;
        }
      }
      for (let i = 0; i < this.reassignmentDriverList.length; i++) {
        if (
          this.reassignmentDriverList[i].driverId ==
          this.reassignmentAfterObj.driverId
        ) {
          obj.driverId = this.reassignmentDriverList[i].driverId;
          obj.driverName = this.reassignmentDriverList[i].driverName;
          obj.driverPhone = this.reassignmentDriverList[i].driverPhone;
          break;
        }
      }
      obj.id = this.popupObj.id;
      obj.departureDateTime = new Date(
        this.popupObj.startDepartureDateTime
      ).getTime();
      updateShiftIntercityScheduleAPI(obj).then((res) => {
        if (res.code === "SUCCESS") {
          this.renderDataFun();
          this.closeFun();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //快速派单
    sendOrderQuickly() {
      quickScheduleTask({ shiftIntercityId: this.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderDataFun();
          this.closeFun();
          this.$message.success("快速派单成功！");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 电召下单
    reserveFun() {
      shiftIntercityAPI({
        operation: 4,
        id: this.popupObj.id,
        remark: this.reserveNumTextarea,
        isAdd: this.reserveRadio == 1 ? true : false,
        reservedTicket: this.reserveNum,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.renderDataFun();
          this.closeFun();
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 暂停 + 启售班次
    suspendFun(operation) {
      shiftIntercityAPI({
        id: this.popupObj.id,
        operation: operation,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.renderDataFun();
          this.closeFun();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 取消任务Fun
    deleteShiftIntercityScheduleByIdFun() {
      deleteShiftIntercityScheduleByIdAPI({ id: this.popupObj.id }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.renderDataFun();
            this.closeFun();
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 弹窗关闭
    closeFun() {
      this.popupShow = null;
      this.sendMoreForm = {
        vehicleId: "", //车辆id
        driverId: "", // 司机id
      };
      this.reassignmentAfterObj = {
        vehicleId: "", //车辆id
        driverId: "", // 司机id
      };
      this.disabled = false;
      this.reserveRadio = "1";
      this.carList = [];
      this.driverList = [];
      this.reassignmentCarList = [];
      this.reassignmentDriverList = [];
    },
    // 预留票成功回调
    reservedCallback() {showReserved
      this.showReserved = false;
      this.handleNodeClick(this.curr_line);
      ``;
    },
    // 菜单操作
    menuFun(val) {
      this.headerText = val.text;
      this.popupShow = val.switch;
      if (val.switch == "add") {
        this.queryCurrentUserFun();
      } else if (val.switch == "reserve") {
        // this.inputNumberMax = this.popupObj.surplusTicket;
        this.showReserved = true;
        this.$nextTick(() => {
          this.$refs.reserved_form?.loadInfo(this.popupObj);
        });
      }
      // 改派操作
      else if (val.switch == "reassignment") {
        console.log(this.popupObj);
        new Promise((resolve) => {
          this.queryDriverFun(this.popupObj.vehicleId, resolve);
        }).then((res) => {
          this.sendMoreForm.vehicleId = this.popupObj.vehicleId;
          this.sendMoreForm.driverId = this.popupObj.driverId;
          this.disabled = true;
          // 过滤车辆和司机
          this.reassignmentCarList = this.carList;
        });
      }
    },
    // 获取弹窗车辆列表数据
    queryCurrentUserFun(resolve) {
      getAllVehicleList({
        lineId: this.popupObj.lineId,
      }).then((res) => {
        console.log(res);
        if (res.code === "SUCCESS") {
          this.carList = res.data;
          resolve && resolve();
        }
      });
    },
    // 获取弹窗司机列表数据
    queryDriverFun(id, resolve) {
      driverIntercityAPI(id + "?isLock=-1").then((res) => {
        console.log(res);
        console.log(this.popupShow);
        if (res.code === "SUCCESS") {
          if (this.popupShow == "reassignment") {
            if (resolve) {
              this.driverList = res.data;
            } else {
              this.reassignmentDriverList = res.data;
            }
          } else {
            this.driverList = res.data;
          }
        }
        resolve && this.queryCurrentUserFun(resolve);
      });
    },
    // 点击打开菜单
    openMenu(e, val, item) {
      this.top = e.clientY;
      this.left = e.clientX;
      this.visible = true;
      console.log("********222",val);
      this.popupObj = val;
      let obj = [];
      // 未调度
      if (val.orderStatus === 0) {
        if (item.ticketShiftIntercityStatus == 1) {
          obj = [
            {
              text: "增派任务",
              class: "iconfont its_zengpaibanci",
              switch: "add",
            },
          ];
        } else {
          obj = [
            {
              text: "增派任务",
              class: "iconfont its_zengpaibanci",
              switch: "add",
            },
            {
              text: "预留车票",
              class: "iconfont its_yuliuchepiao",
              switch: "reserve",
            },
          ];
        }
      }
      // 待派单
      else if (val.orderStatus === 10) {
        obj = [
          {
            text: "改派任务",
            class: "iconfont its_gaipai",
            switch: "reassignment",
          },
          {
            text: "取消任务",
            class: "iconfont its_quxiao1",
            switch: "cancel",
          },
          {
            text: "预留车票",
            class: "iconfont its_yuliuchepiao",
            switch: "reserve",
          },
        ];

        if (val.driverId && val.licensePlateNumber) {
          this.id = val.id;
          const date = new Date(val.departureDate + " 00:00:00").getTime();
          const date2 = date + 3600 * 24 * 1000;
          if (date < new Date().getTime() && new Date().getTime() < date2) {
            obj.push({
              text: "快速派单",
              class: "iconfont its_kuaisupaidan",
              switch: "quicklysend",
            });
          }
        }
      }
      // 已派单
      else if (val.orderStatus === 20) {
        obj = [
          {
            text: "改派任务",
            class: "iconfont its_gaipai",
            switch: "reassignment",
          },
          {
            text: "预留车票",
            class: "iconfont its_yuliuchepiao",
            switch: "reserve",
          },
        ];
      }
      // 行程中
      else if (val.orderStatus === 20) {
        obj = [
          {
            text: "预留车票",
            class: "iconfont its_yuliuchepiao",
            switch: "reserve",
          },
        ];
      }

      // 如果是已停班
      if (
        val.ticketShiftIntercityStatus == 3 &&
        item.ticketShiftIntercityStatus != 1
      ) {
        obj = [
          {
            text: "启售班次",
            class: "iconfont its_qidongbanci",
            switch: "enable",
          },
        ];
      } else if (
        val.soldTicketNum === 0 &&
        item.ticketShiftIntercityStatus != 1
      ) {
        obj.push({
          text: "暂停班次",
          class: "iconfont its_zantingbanci",
          switch: "suspend",
        });
      }
      this.menuBox = obj;
    },
    closeMenu() {
      this.visible = false;
    },
    // 背景色判断
    colorManageFun(val, type) {
      // 如果是外层包裹box
      if (type == "box") {
        if (val === 0) {
          return "area-box-green";
        } else if (val === 1) {
          return "area-box-grey";
        } else if (val === 2) {
          return "area-box-orange";
        } else if (val === 3) {
          return "area-box-red";
        } else {
          return "";
        }
      } else if (type == "son") {
        if (val === 0) {
          return "area-box-left-green";
        } else if (val === 1) {
          return "area-box-left-grey";
        } else if (val === 2) {
          return "area-box-left-orange";
        } else if (val === 3) {
          return "area-box-left-red";
        } else {
          return "";
        }
      }
    },
    // 班次状态判断 class + 背景色
    orderStatusFun(val, type, typeStatus) {
      if (type == "text") {
        if (typeStatus == 3) {
          return "已停班";
        } else {
          if (val === 0) {
            return "未调度";
          } else if (val === 10) {
            return "待派单";
          } else if (val === 20) {
            return "已派单";
          } else if (val === 30) {
            return "已接驾";
          } else if (val === 31) {
            return "行程中";
          } else if (val === 40) {
            return "已完成";
          } else {
            return "";
          }
        }
      } else if (type == "class") {
        if (typeStatus == 3) {
          return "shift-status-red";
        } else {
          if (val === 0) {
            return "shift-status-grey";
          } else if (val === 10) {
            return "shift-status-blue";
          } else if (val === 20) {
            return "shift-status-green2";
          } else if (val === 30) {
            return "已接驾";
          } else if (val === 31) {
            return "shift-status-orange";
          } else if (val === 40) {
            return "shift-status-green";
          } else {
            return "";
          }
        }
      }
    },
    // 售票班次状态
    ticketShiftStatus(val) {
      if (val === 0) {
        return "售票中";
      } else if (val === 1) {
        return "已停售";
      } else if (val === 2) {
        return "已售罄";
      } else if (val === 3) {
        return "已停班";
      } else {
        return "";
      }
    },
    // 班线状态 + class判断
    shiftType(val, type) {
      if (type == "type") {
        if (val === 0) {
          return "定班";
        } else if (val === 1) {
          return "加班";
        } else if (val === 2) {
          return "改班";
        } else if (val === 3) {
          return "撤班";
        } else {
          return "";
        }
      } else if (type == "class") {
        if (val === 0) {
          return "shift-blue";
        } else if (val === 1) {
          return "shift-red";
        } else if (val === 2) {
          return "shift-grey";
        } else if (val === 3) {
          return "shift-yellow";
        } else {
          return "";
        }
      }
    },
    // 获取左侧线路菜单树数据
    renderTreeFun() {
      queryShiftIntercityScheduleMainLineTreeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.menuData = res.data;
          // 如果是第一次进入
          if (this.firstOnoff) {
            this.handleNodeClick(res.data[0]);
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(res.data[0].lineCode);
            });
            this.firstOnoff = false;
          }
        }
      });
    },
    // 菜单树点击回调
    handleNodeClick(val) {
      this.closeMenu();
      this.curr_line = val;
      // 判断如果点击的是主线路
      if (val.lineCode) {
        // 如果有子线路数据
        if (val.childLines) {
          let ar = [];
          // 循环所有子线路id
          val.childLines.map((item) => {
            ar.push(item.id);
          });
          this.lineIds = ar.join(",");
          this.renderDataFun();
        }
      } else {
        this.lineIds = val.id;
        this.renderDataFun();
      }
    },
    // 获取任务管理数据
    renderDataFun() {
      queryScheduleTasksAPI({
        startTime: this.startTime,
        endTime: this.endTime,
        lineId: this.lineIds,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.renderData = res.data;
          // this.$refs.dispatchBox.scrollTop = 0;
        }
      });
    },
    // 获取时间
    getTimeFun(val) {
      this.startTime = val[0];
      this.endTime = val[1];
      if (this.firstOnoff) {
        // this.firstOnoff = false;
      } else {
        this.renderDataFun();
      }
    },
  },
};
</script>

<style scoped lang="scss">
$dis-orange: #ff8627;
$dis-red: #f75959;
$dis-green: #00c777;
$dis-green2: #a0d22d;
$dis-blue: #336ffe;
$dis-grey: #cecece;

$dis-orange-bg: #ff862710;
$dis-red-bg: #f7595910;
$dis-green-bg: #00c77710;
$dis-green2-bg: #a0d22d10;
$dis-blue-bg: #336ffe10;
$dis-grey-bg: #cecece10;
.form-label {
  width: 200px;
}
.operationPopup {
  z-index: 777;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .manual-content {
    width: 500px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 200px;
    .content {
      margin-top: 60px;
      margin-bottom: 60px;
      width: calc(100% - 80px);
      padding: 10px 0 0 80px;
      min-height: 80px;
      h2 {
        margin-left: -20px;
      }

      .pureText {
        font-size: 14px;
        padding-right: 80px;
        position: absolute;
        line-height: 22px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.header {
  padding: 18px 24px;
  height: 24px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  top: 0;
  border-bottom: 1px solid #d7d7d7;
  text-align: center;
  .text {
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
  }
  .close {
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: url("../../../assets/images/home/close.png") no-repeat;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.footer {
  padding: 10px 24px 18px 24px;
  height: 32px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  border-bottom: none !important;
  border-top: 1px solid #d7d7d7;
  bottom: 0 !important;
  display: flex;
  justify-content: center;
  .el-button {
    float: right;
    margin-left: 14px;
  }
  .el-button:first-child {
    background: #336fee;
    color: #ffffff;
  }
}

.contextmenu {
  width: 128px;
  position: absolute;
  z-index: 120;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
  li {
    height: 32px;
    font-size: 14px;
    color: #333333;
    line-height: 32px;
    cursor: pointer;
    .iconfont {
      font-size: 16px;
      margin-left: 12px;
      margin-right: 8px;
    }
  }
  li:hover {
    color: #3671fe;
    background: #edf4ff;
  }
}

.dispatchManage {
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 1160px;
  .dispatch-content {
    width: calc(100% - 218px);
    overflow: hidden;
    margin-left: 10px;
    box-shadow: 0px 0px 4px 0px rgba(52, 52, 52, 0.1);
    border-radius: 4px;
    .dispatch-box {
      height: calc(100% - 74px);
      background: #ffffff;
      border-top: 1px solid #ececec;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      .defaultPage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #999999;
        font-size: 16px;
        text-align: center;
        p {
          width: 100%;
          text-align: center;
        }
        span {
          color: #ff8627;
        }
        p:last-child {
          font-size: 12px;
          margin-top: 18px;
        }
      }
      .time-row {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #ececec;
        .time {
          width: 86px;
          border-right: 1px solid #ececec;
          height: auto;
          position: relative;
          div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .time-result {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .time-text {
            font-size: 14px;
            color: #666666;
            position: relative;
            padding-left: 14px;
            .shift-blue {
              background: #336ffe;
            }
            .shift-red {
              background: #d40000;
            }
            .shift-grey {
              background: #cecece;
            }
            .shift-yellow {
              background: #ff8627;
            }
            span {
              position: absolute;
              display: inline-block;
              width: 6px;
              height: 6px;
              left: 0;
              background: #336ffe;
              border-radius: 50%;
              margin-right: 6px;
              top: 50%;
              margin-top: -2px;
            }
          }
        }
        .dis-block {
          width: calc(100% - 86px);
          height: 100%;
          display: flex;
          .area {
            padding: 2px;
            width: 33%;
            height: 100%;
            border-right: 1px solid #ececec;
            .area-box-green {
              border-color: $dis-green !important;
              background: $dis-green-bg !important;
            }
            .area-box-grey {
              border-color: $dis-grey !important;
              background: $dis-grey-bg !important;
            }
            .area-box-orange {
              border-color: $dis-orange !important;
              background: $dis-orange-bg !important;
            }
            .area-box-red {
              border-color: $dis-red !important;
              background: $dis-red-bg !important;
            }
            .area-box {
              max-width: 280px;
              border: 2px solid;
              border-radius: 10px;
              overflow: hidden;
              display: flex;
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              .area-box-left-green {
                background: $dis-green;
              }
              .area-box-left-grey {
                background: $dis-grey;
              }
              .area-box-left-orange {
                background: $dis-orange;
              }
              .area-box-left-red {
                background: $dis-red;
              }
              .area-box-left {
                width: 42px;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                .statusLabel {
                  font-size: 14px;
                  color: #ffffff;
                  width: 18px;
                  display: inline-block;
                }
                .statusPoll {
                  width: 100%;
                  font-size: 14px;
                  color: #ffffff;
                  margin-top: 10px;
                }
              }
              .area-box-right {
                width: calc(100% - 40px);
                padding: 9px 5px;
                .area-shift {
                  padding: 5px 0;
                  border-radius: 4px;
                  .shift-box:hover {
                    background: #ffffff;
                    box-shadow: 0px 2px 24px 0px rgba(200, 201, 204, 0.5);
                    transition: all 0.25s;
                  }
                  .shift-box:hover .iconfont {
                    transition: all 0.25s;
                    display: inline-block !important;
                  }
                  .shift-box:last-child {
                    margin-bottom: 0;
                  }
                  .shift-box {
                    height: 45px;
                    display: flex;
                    margin-top: 9px;
                    padding: 9px 5px;

                    &:nth-child(1) {
                      margin-top: 0;
                    }
                    .shift-info {
                      flex-shrink: 0;
                      font-size: 12px;
                      width: calc(100% - 54px);
                      height: 100%;
                      position: relative;
                      .shift-address {
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        .its_menu {
                          font-size: 16px;
                          color: #336fee;
                          right: 0;
                          position: absolute;
                          display: none;
                        }
                      }
                      .shift-drivers {
                        width: 100%;
                        font-size: 12px;
                        color: #666666;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        div {
                          position: absolute;
                          right: 0;
                          bottom: 0;
                          span {
                            color: #336ffe;
                          }
                        }
                      }
                    }
                    .shift-status-red {
                      background: $dis-red;
                    }
                    .shift-status-grey {
                      background: $dis-grey;
                    }
                    .shift-status-blue {
                      background: $dis-blue;
                    }
                    .shift-status-green2 {
                      background: $dis-green2;
                    }
                    .shift-status-orange {
                      background: $dis-orange;
                    }
                    .shift-status-green {
                      background: $dis-green;
                    }
                    .shift-status {
                      flex-shrink: 0;
                      margin-right: 5px;
                      width: 45px;
                      border-radius: 4px;
                      text-align: center;
                      line-height: 45px;
                      border-radius: 4px;
                      color: #ffffff;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
          .area:last-child {
            border-right: none;
          }
        }
      }
    }
    .dispatch-date {
      background: #fafbfc;
      height: 74px;
    }
  }
  .menuBar {
    border: 1px solid #e9e9e9;
    box-shadow: 0px 0px 4px 0px rgba(52, 52, 52, 0.1);
    border-radius: 4px;
    width: 208px;
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    .el-tree {
      padding-right: 8px;
    }
    /deep/.el-tree-node__content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }
    /deep/.el-tree-node {
      width: calc(100% - 20px);
      margin: 12px 0;
      margin-left: 20px !important;
    }
    .span-ellipsis {
      font-size: 14px;
    }
    /deep/.el-tree-node__children {
      .span-ellipsis {
        margin-left: 10px;
      }
    }
    .its_sm_luxian {
      color: #336ffe;
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
// 移入时候样式
/deep/.el-tree-node__content:hover {
  background: none !important;
}
// tree选中节点样式
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: none;
  color: #336ffe !important;
}
</style>
