var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carousel-time-page" }, [
    _c("div", { staticClass: "return-today" }, [
      _c("div", { staticClass: "today", on: { click: _vm.returnFun } }, [
        _vm._v("今天"),
      ]),
    ]),
    _c("div", { staticClass: "time" }, [
      _c(
        "div",
        {
          staticClass: "time-img",
          staticStyle: { "margin-left": "48px" },
          on: {
            click: function ($event) {
              return _vm.changeTimeList(-3)
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/home/gysm7.1_left.svg"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "time-text" },
        _vm._l(_vm.timeArr, function (item, index) {
          return _c(
            "div",
            { key: index, staticStyle: { position: "relative" } },
            [
              _c(
                "div",
                {
                  class: [
                    "time-display",
                    item.t.includes("今天") ? "active-time" : "",
                  ],
                },
                [_vm._v(" " + _vm._s(item.t) + " ")]
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "time-img",
          staticStyle: { "margin-right": "48px" },
          on: {
            click: function ($event) {
              return _vm.changeTimeList(3)
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/home/gysm7.1_right.svg"),
              alt: "",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }