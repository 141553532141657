var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reserve_form" },
    [
      _c("div", { staticClass: "manual-content" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "text" }, [_vm._v("预留车票")]),
          _c("div", { staticClass: "close", on: { click: _vm.closeFun } }),
        ]),
        _c(
          "div",
          { staticClass: "content-6" },
          [
            _c(
              "el-form",
              {
                ref: "reserveForm",
                staticClass: "reserveForm",
                attrs: {
                  "label-width": "100px",
                  model: _vm.reserve,
                  rules: _vm.reserveRules,
                },
              },
              [
                _c(
                  "el-row",
                  { staticStyle: { padding: "20px" } },
                  [
                    _c("h4", [_vm._v("预留班次信息")]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "出发地：", prop: "startStation" },
                          },
                          [
                            _vm.startStation && _vm.reserveEditable
                              ? _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.reserve.startStation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.reserve,
                                          "startStation",
                                          $$v
                                        )
                                      },
                                      expression: "reserve.startStation",
                                    },
                                  },
                                  _vm._l(
                                    _vm.startStation,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          value: item.label,
                                          label: item.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("el-input", {
                                  attrs: {
                                    placeholder: "出发地",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.reserve.startStation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.reserve, "startStation", $$v)
                                    },
                                    expression: "reserve.startStation",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "到达地：", prop: "endStation" } },
                          [
                            _vm.endStation && _vm.reserveEditable
                              ? _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.reserve.endStation,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.reserve, "endStation", $$v)
                                      },
                                      expression: "reserve.endStation",
                                    },
                                  },
                                  _vm._l(
                                    _vm.endStation,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          value: item.label,
                                          label: item.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("el-input", {
                                  attrs: {
                                    disabled: "",
                                    placeholder: "到达地",
                                  },
                                  model: {
                                    value: _vm.reserve.endStation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.reserve, "endStation", $$v)
                                    },
                                    expression: "reserve.endStation",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "出行日期：",
                              prop: "departureDate",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                disabled: !_vm.reserveEditable,
                                placeholder: "出行日期",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.reserve.departureDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.reserve, "departureDate", $$v)
                                },
                                expression: "reserve.departureDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "班次时间：",
                              prop: "departureTime",
                            },
                          },
                          [
                            _vm.reserveEditable
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      loading: _vm.Times_loading,
                                      placeholder: "请选择班次",
                                    },
                                    model: {
                                      value: _vm.reserve.departureId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.reserve,
                                          "departureId",
                                          $$v
                                        )
                                      },
                                      expression: "reserve.departureId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.workForceTimes,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          value: item.id,
                                          label:
                                            item.departureTime +
                                            "- 余票" +
                                            item.surplusTicket,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.time_ticket,
                                    callback: function ($$v) {
                                      _vm.time_ticket = $$v
                                    },
                                    expression: "time_ticket",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "上车点：" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "88%" },
                              attrs: {
                                readonly: true,
                                placeholder: "请选择上车点",
                              },
                              model: {
                                value: _vm.reserve.startAddress,
                                callback: function ($$v) {
                                  _vm.$set(_vm.reserve, "startAddress", $$v)
                                },
                                expression: "reserve.startAddress",
                              },
                            }),
                            _c("i", {
                              staticClass: "iconfont its_gy_map",
                              on: {
                                click: function ($event) {
                                  return _vm.selectAddress(0)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "下车点：" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "88%" },
                              attrs: {
                                readonly: true,
                                placeholder: "请选择下车点",
                              },
                              model: {
                                value: _vm.reserve.endAddress,
                                callback: function ($$v) {
                                  _vm.$set(_vm.reserve, "endAddress", $$v)
                                },
                                expression: "reserve.endAddress",
                              },
                            }),
                            _c("i", {
                              staticClass: "iconfont its_gy_map",
                              on: {
                                click: function ($event) {
                                  return _vm.selectAddress(1)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "预留数量：",
                              prop: "reservedTicket",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                min: 0,
                                max: _vm.reserveSurplusTicket,
                                size: "small",
                              },
                              on: { change: _vm.changeReservedTicket },
                              model: {
                                value: _vm.reserve.reservedTicket,
                                callback: function ($$v) {
                                  _vm.$set(_vm.reserve, "reservedTicket", $$v)
                                },
                                expression: "reserve.reservedTicket",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.show
                          ? _c("p", { staticClass: "sign" }, [
                              _vm._v(" 若要继续减少预留数量，请先减少携童数 "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "携童数：",
                              prop: "carryingChildrenNum",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                min: 0,
                                max:
                                  _vm.children >= _vm.reserve.reservedTicket
                                    ? _vm.reserve.reservedTicket
                                    : _vm.children,
                                size: "small",
                              },
                              on: { change: _vm.changeReservedTicket },
                              model: {
                                value: _vm.reserve.carryingChildrenNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.reserve,
                                    "carryingChildrenNum",
                                    $$v
                                  )
                                },
                                expression: "reserve.carryingChildrenNum",
                              },
                            }),
                            _c("div", [
                              _c("span", { staticStyle: { color: "gray" } }, [
                                _vm._v(" 此班次剩余携童票 "),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(_vm.children)),
                                ]),
                                _vm._v(" 张 "),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("h4", [_vm._v("乘客信息")]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "预留人：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请填写预留人姓名",
                                maxlength: "10",
                              },
                              model: {
                                value: _vm.reserve.reservedTicketPassenger,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.reserve,
                                    "reservedTicketPassenger",
                                    $$v
                                  )
                                },
                                expression: "reserve.reservedTicketPassenger",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "预留手机号：",
                              prop: "reservedTicketPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请填写预留手机号",
                                maxlength: "13",
                              },
                              on: { blur: _vm.setPassword },
                              model: {
                                value: _vm.reserve.reservedTicketPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.reserve,
                                    "reservedTicketPhone",
                                    $$v
                                  )
                                },
                                expression: "reserve.reservedTicketPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "预留密码：",
                              prop: "reservedTicketPassword",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请填写预留密码",
                                disabled: "",
                              },
                              model: {
                                value: _vm.reserve.reservedTicketPassword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.reserve,
                                    "reservedTicketPassword",
                                    $$v
                                  )
                                },
                                expression: "reserve.reservedTicketPassword",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                maxlength: "30",
                                rows: "4",
                              },
                              model: {
                                value: _vm.reserve.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.reserve, "remark", $$v)
                                },
                                expression: "reserve.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.submit } },
              [_vm._v("保存")]
            ),
            _c("el-button", { on: { click: _vm.closeFun } }, [_vm._v("取消")]),
          ],
          1
        ),
      ]),
      _vm.mapDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.areaType ? "选择下车点" : "选择上车点",
                visible: _vm.mapDialog,
                width: "1000px",
                "close-on-click-modal": false,
                modal: false,
              },
              on: { close: _vm.cancelBtn },
            },
            [
              _c("div", { staticClass: "map" }, [
                _vm.areaMode == 0
                  ? _c("div", { staticClass: "searchbox" }, [
                      _c(
                        "header",
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchInput,
                                expression: "searchInput",
                              },
                            ],
                            attrs: { type: "text", placeholder: "搜索地址" },
                            domProps: { value: _vm.searchInput },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.searchInput = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "el-button",
                            { attrs: { type: "primary", size: "small" } },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pointlist" },
                        _vm._l(_vm.addressList, function (item, index) {
                          return _c(
                            "p",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.searchClick(item)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.address) + " ")]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "select",
                    style: { top: _vm.areaMode == 0 ? "55px" : "14px" },
                  },
                  [
                    _c("header", [_vm._v("请选择地点")]),
                    _vm.areaMode == 0
                      ? _c("p", [_vm._v(_vm._s(_vm.point.address))])
                      : _vm._e(),
                    _vm.areaMode == 1
                      ? _c(
                          "div",
                          { staticClass: "point" },
                          _vm._l(_vm.pointList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "item",
                                class: { item1: _vm.currentIndex === index },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectPoint(item, index)
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "detial",
                                    attrs: { title: item.address },
                                  },
                                  [_vm._v(" " + _vm._s(item.address) + " ")]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
                _c("div", { attrs: { id: "container" } }),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.cancelBtn },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("选择")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }