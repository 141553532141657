var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dispatchManage" },
    [
      _c(
        "div",
        { staticClass: "menuBar" },
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              "node-key": "lineCode",
              "expand-on-click-node": false,
              "highlight-current": true,
              data: _vm.menuData,
              props: _vm.menuDefaultProps,
            },
            on: { "node-click": _vm.handleNodeClick },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return _c("span", { staticClass: "span-ellipsis" }, [
                    data.lineCode
                      ? _c("i", { staticClass: "iconfont its_sm_luxian" })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "menuLabel",
                        attrs: { title: node.label },
                      },
                      [_vm._v(_vm._s(node.label))]
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "dispatch-content" }, [
        _c(
          "div",
          { staticClass: "dispatch-date" },
          [
            _c("carouselTime", {
              ref: "carouselTimeRef",
              on: { getTimeFun: _vm.getTimeFun },
            }),
          ],
          1
        ),
        _vm.renderData.length > 0
          ? _c(
              "div",
              { ref: "dispatchBox", staticClass: "dispatch-box" },
              _vm._l(_vm.renderData, function (item) {
                return _c("div", { key: item.time, staticClass: "time-row" }, [
                  _c("div", { staticClass: "time" }, [
                    _c("div", [
                      _c("p", { staticClass: "time-result" }, [
                        _vm._v(_vm._s(item.time)),
                      ]),
                      _c("p", { staticClass: "time-text" }, [
                        _c("span", {
                          class: _vm.shiftType(item.type, "class"),
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.shiftType(item.type, "type")) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "dis-block" },
                    _vm._l(item.scheduleTaskVO, function (jump, index) {
                      return _c("div", { key: index, staticClass: "area" }, [
                        jump
                          ? _c(
                              "div",
                              {
                                staticClass: "area-box",
                                class: _vm.colorManageFun(
                                  jump.ticketShiftIntercityStatus,
                                  "box"
                                ),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "area-box-left",
                                    class: _vm.colorManageFun(
                                      jump.ticketShiftIntercityStatus,
                                      "son"
                                    ),
                                  },
                                  [
                                    _c("p", { staticClass: "statusLabel" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ticketShiftStatus(
                                              jump.ticketShiftIntercityStatus
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("p", { staticClass: "statusPoll" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(jump.totalSoldTicketNum) +
                                          "/" +
                                          _vm._s(jump.totalTicketNum) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "area-box-right" },
                                  _vm._l(
                                    jump.scheduleTaskPOS,
                                    function (project) {
                                      return _c(
                                        "div",
                                        {
                                          key: project.id,
                                          staticClass: "area-shift",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.openMenu(
                                                $event,
                                                project,
                                                jump
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "shift-box" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "shift-status",
                                                  class: _vm.orderStatusFun(
                                                    project.orderStatus,
                                                    "class",
                                                    project.ticketShiftIntercityStatus
                                                  ),
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.orderStatusFun(
                                                          project.orderStatus,
                                                          "text",
                                                          project.ticketShiftIntercityStatus
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "shift-info" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "shift-address",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            project.lineName
                                                          ) +
                                                          " "
                                                      ),
                                                      (
                                                        project.orderStatus ==
                                                          40 ||
                                                        project.orderStatus ==
                                                          31
                                                          ? false
                                                          : true
                                                      )
                                                        ? _c("i", {
                                                            staticClass:
                                                              "iconfont its_menu",
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "shift-drivers",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                project.orderStatus !==
                                                                0,
                                                              expression:
                                                                "project.orderStatus !== 0",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                project.licensePlateNumber
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                project.driverName
                                                                  ? project.driverName
                                                                  : ""
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              project.reservedTicket
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v("/ "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              project.soldTicketNum
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " /" +
                                                            _vm._s(
                                                              project.surplusTicket
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ])
              }),
              0
            )
          : _c("div", { staticClass: "dispatch-box" }, [_vm._m(0)]),
      ]),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        _vm._l(_vm.menuBox, function (item) {
          return _c(
            "li",
            {
              key: item.text,
              on: {
                click: function ($event) {
                  return _vm.menuFun(item)
                },
              },
            },
            [
              _c("i", { class: item.class }),
              _vm._v(" " + _vm._s(item.text) + " "),
            ]
          )
        }),
        0
      ),
      _vm.popupShow && _vm.popupShow != "reserve"
        ? _c("div", { staticClass: "operationPopup" }, [
            _c("div", { staticClass: "manual-content" }, [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.headerText)),
                ]),
                _c("div", {
                  staticClass: "close",
                  on: { click: _vm.closeFun },
                }),
              ]),
              _vm.popupShow == "add" || _vm.popupShow == "reassignment"
                ? _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            model: _vm.sendMoreForm,
                            "label-position": "left",
                            "label-width": "80px",
                          },
                        },
                        [
                          _vm.popupShow == "reassignment"
                            ? _c("h2", [_vm._v("原调度任务")])
                            : _vm._e(),
                          _c("el-form-item", { attrs: { label: "线路：" } }, [
                            _c("p", { staticClass: "form-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.popupObj.startStation) +
                                  "-" +
                                  _vm._s(_vm.popupObj.endStation) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "发车时间：" } },
                            [
                              _c("p", { staticClass: "form-label" }, [
                                _vm._v(
                                  _vm._s(_vm.popupObj.startDepartureDateTime)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "车辆：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择车辆",
                                    disabled: _vm.disabled,
                                  },
                                  on: { change: _vm.carListChange },
                                  model: {
                                    value: _vm.sendMoreForm.vehicleId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sendMoreForm,
                                        "vehicleId",
                                        $$v
                                      )
                                    },
                                    expression: "sendMoreForm.vehicleId",
                                  },
                                },
                                _vm._l(_vm.carList, function (role) {
                                  return _c("el-option", {
                                    key: role.id,
                                    attrs: {
                                      label:
                                        role.brand +
                                        "-" +
                                        role.licensePlateNumber +
                                        "-" +
                                        role.seatNumber +
                                        "座",
                                      value: role.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "司机：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择司机",
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: _vm.sendMoreForm.driverId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sendMoreForm,
                                        "driverId",
                                        $$v
                                      )
                                    },
                                    expression: "sendMoreForm.driverId",
                                  },
                                },
                                _vm._l(_vm.driverList, function (role) {
                                  return _c("el-option", {
                                    key: role.driverId,
                                    attrs: {
                                      label:
                                        role.driverName +
                                        "-" +
                                        role.driverPhone,
                                      value: role.driverId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.popupShow == "reassignment"
                            ? _c("h2", [_vm._v("改派后调度任务")])
                            : _vm._e(),
                          _vm.popupShow == "reassignment"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "车辆：" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择车辆",
                                          },
                                          on: { change: _vm.carListChange },
                                          model: {
                                            value:
                                              _vm.reassignmentAfterObj
                                                .vehicleId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.reassignmentAfterObj,
                                                "vehicleId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "reassignmentAfterObj.vehicleId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.reassignmentCarList,
                                          function (role) {
                                            return _c("el-option", {
                                              key: role.id,
                                              attrs: {
                                                label:
                                                  role.brand +
                                                  "-" +
                                                  role.licensePlateNumber +
                                                  "-" +
                                                  role.seatNumber +
                                                  "座",
                                                value: role.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "司机：" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择司机",
                                          },
                                          model: {
                                            value:
                                              _vm.reassignmentAfterObj.driverId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.reassignmentAfterObj,
                                                "driverId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "reassignmentAfterObj.driverId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.reassignmentDriverList,
                                          function (role) {
                                            return _c("el-option", {
                                              key: role.driverId,
                                              attrs: {
                                                label:
                                                  role.driverName +
                                                  "-" +
                                                  role.driverPhone,
                                                value: role.driverId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.popupShow == "cancel"
                ? _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "pureText" }, [
                      _vm._v("取消任务后，系统将不会进行自动调度！是否继续？"),
                    ]),
                  ])
                : _vm._e(),
              _vm.popupShow == "suspend"
                ? _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "pureText" }, [
                      _vm._v(
                        " 暂停班次后，乘客将无法购买该班次的车票！是否继续？ "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.popupShow == "enable"
                ? _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "pureText" }, [
                      _vm._v("是否要启售班次？"),
                    ]),
                  ])
                : _vm._e(),
              _vm.popupShow == "quicklysend"
                ? _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "pureText" }, [
                      _vm._v("派单后，司机会收到派单通知，可发车接送乘客"),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "footer" }, [
                _c(
                  "div",
                  [
                    _c("el-button", { on: { click: _vm.confirmFun } }, [
                      _vm._v(
                        _vm._s(
                          _vm.popupShow == "quicklysend" ? "确认派单" : "确认"
                        )
                      ),
                    ]),
                    _c("el-button", { on: { click: _vm.closeFun } }, [
                      _vm._v("取消"),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.showReserved
        ? _c("reserved-form", {
            ref: "reserved_form",
            attrs: { rate: _vm.rate },
            on: {
              closePopupFun: function ($event) {
                _vm.showReserved = false
              },
              saveOk: _vm.reservedCallback,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "defaultPage" }, [
      _c("p", [_vm._v("暂无调度班次")]),
      _c("p", [
        _c("span", [_vm._v("请稍后再试，")]),
        _vm._v("或者切换其他的线路试试"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }